<template>
  <div id="divLegalWarningWrapper">
    <VueWrapperNarrow class="wrapper-narrow">
      <VueHeadline level="2" weightLevel="3">{{ item.header }}</VueHeadline>
      <VueText
        class="contract-body"
        weightLevel="2"
        sizeLevel="4"
        color="grey-40"
        v-html="item.text"
      ></VueText>
    </VueWrapperNarrow>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'LegalWarning',
  components: {
    VueText,
    VueHeadline,
    VueWrapperNarrow,
  },
  data() {
    return {
      item: {
        header: 'Yasal Şartlar',
        text: '',
      },
    };
  },
  created() {
    StaticContent.getStaticContent('YasalUyari').then(res => {
      if (res.data.Data) {
        this.item.text = res.data.Data.content;
      }
    });
  },
};
</script>

<style scoped lang="scss">
.wrapper-narrow {
  padding-top: palette-space-level('60');
}

.contract-body {
  line-height: 1.71;
  padding-top: palette-space-level('20');
  font-weight: 300;
}
</style>
